import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { getUser, setUser, isLoggedIn } from "../../../services/auth"
import config from '../../../config/config'
import axios from "axios";

const EditBoardModal = ({ show, board, handleClose}) => {
    var Member = getUser();
    const [isDeleteBoard, setIsDeleteBoard] = useState(false)
    var [showEditBoardModal, setShowEditBoardModal] = useState(false);
    useEffect(() => {
        setShowEditBoardModal(show)
      })
    const updateBoard = (event) => {
        event.preventDefault()
        let newBoardName = event.target.elements.boardname.value;
        let boardID = board.ID
        if (isLoggedIn()) {
            var _data = {
              enu: btoa(Member.Email),
              boardname: newBoardName,
              id: boardID,
            };
          axios.post(config.host + "/tribecaapi/updateboard", _data)
            .then(response => {
              let _response_data = response.data;
              if (_response_data) {
                Member.Boards = _response_data;
                setUser(Member);
              }
              handleClose();
            }).catch(function (error) {
          });
        }
    }
    const copyBoard = (event) => {
        event.preventDefault()
        let boardID = board.ID
        if (isLoggedIn()) {
          var _data = {
            enu: btoa(Member.Email),
            id: boardID,
          };
        axios.post(config.host + "/tribecaapi/copyboard", _data)
          .then(response => {
            let _response_data = response.data;
            if (_response_data) {
              Member.Boards = _response_data;
              setUser(Member);
              window.location.href = "/account/my-boards/";
            }
            handleClose();
          }).catch(function (error) {
        });
        }
    }
    
    const confirmDeleteBoard = (event) => {
        setIsDeleteBoard(true)
    }
    
    const deleteBoard = (event) => {
        event.preventDefault()
        let boardID = board.ID
        if (isLoggedIn()) {
            var _data = {
                enu: btoa(Member.Email),
                id: boardID,
            };
            axios.post(config.host + "/tribecaapi/deleteboard", _data)
                .then(response => {
                  let _response_data = response.data;
                  if (_response_data) {
                    Member.Boards = _response_data;
                    setUser(Member);
                  }
                  handleClose();
                }).catch(function (error) {
          });
        }
    }
  return (
    <Modal show={showEditBoardModal} className="boardModal"
        onHide={handleClose}
    >
        <div className="boardModal-inner">
        {isDeleteBoard ?
            <div className="board-form">
                <h4>Delete Board?</h4>
                <form onSubmit={event => { deleteBoard(event) }}>
                  <div className="d-flex justify-content-between mt-5">            
                    <button className="btn btn-black btn-lg w-100" onClick={() => deleteBoard()}>YES, DELETE THE BOARD {board.Name}</button>
                  </div>
                </form>
            </div>
        :
            <>
                <div className="board-form border-bottom border-dark pb-2">
                    <h4>Rename Board</h4>
                    <form onSubmit={event => { updateBoard(event) }}>
                      <div className="form-group">
                        <input required name="boardname" placeholder="New board name" className="form-control" />
                      </div>
                      <div className="d-flex justify-content-end mt-5">            
                        <button type="submit" className="btn btn-black btn-lg btn-w160">SAVE</button>
                      </div>
                    </form>
                </div>
                <div className="board-form border-bottom border-dark pb-2">
                    <h4 className="mt-2">Copy Board</h4>
                    <form onSubmit={event => { copyBoard(event) }}>
                        <div className="form-group">
                            {board.Name} copy
                        </div>
                      <div className="d-flex justify-content-end mt-5">                  
                        <button type="submit" className="btn btn-black btn-lg btn-w160">COPY</button>
                      </div>
                    </form>
                </div>
                <div className="board-form mt-4">
                    <div className="d-flex justify-content-between">
                        <button className="btn text-cancel px-0" onClick={() => confirmDeleteBoard()}>Delete Board</button>
                        <button className="btn text-cancel px-0" onClick={() => handleClose()}>Cancel</button>
                    </div>
                </div>
            </>
        }
        </div>
    </Modal>
  )
}

export default EditBoardModal
